import React from 'react'
import Thank from '../assets/image/thanku.png'


function ThankU() {
  return (
    <div>

      <div className='container d-flex justify-content-center align-items-center py-5'>
      <img className='m-auto' src={Thank}  alt='ThankU'/>
      </div>
      
    </div>
  )
}

export default ThankU
